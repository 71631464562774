// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/context/signupContext.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/context/signupContext.tsx");
  import.meta.hot.lastModified = "1714779469550.2974";
}
// REMIX HMR END

import { createContext, useState } from "react";
const initialState = {
  full_name: "",
  username: "",
  email: "",
  password: "",
  package_plan_id: 0,
  payfastLink: "",
  signupStatus: false,
  message: ""
};
export const SignupContext = createContext(initialState);
const SignupContextProvider = props => {
  _s();
  const {
    children
  } = props;
  const [full_name, setFullName] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [package_plan_id, setPackagePlanId] = useState(0);
  const [payfastLink, setPayfastLink] = useState("");
  const [signupStatus, setSignupStatus] = useState(false);
  const [message, setMessage] = useState("");
  return <SignupContext.Provider value={{
    full_name,
    setFullName,
    username,
    setUserName,
    password,
    setPassword,
    email,
    setEmail,
    package_plan_id,
    setPackagePlanId,
    payfastLink,
    setPayfastLink,
    signupStatus,
    setSignupStatus,
    message,
    setMessage
  }}>
      {children}
    </SignupContext.Provider>;
};
_s(SignupContextProvider, "fAwvOGgb5alNxjlo+ZICDYSqFDI=");
_c = SignupContextProvider;
export default SignupContextProvider;
var _c;
$RefreshReg$(_c, "SignupContextProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;